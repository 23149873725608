













































































































































import { Component, Vue } from "vue-property-decorator";
import AppApi from "@/api/application/AppApi";
import CusMenuItem from "@/views/flow-deal/components/cus-menu-item.vue";
import TokenUtil from "global-ui/packages/utils/TokenUtil";

@Component({
  name: "UserHomeFlowCreate",
  components: {
    CusMenuItem,
  },
})
export default class UserHomeFlowCreate extends Vue {
  // 默认选中的应用树
  defaultActiveMenu: string = "";

  // 应用树类型加载状态
  typeTreeLoading: boolean = false;
  // 应用树类型数据
  typeTreeData: any[] = [];

  // 流程列表加载状态
  flowListLoading: boolean = false;
  // 流程列表数据
  flowListData: any[] = [];

  created() {
    this.getTypeTreeData();
  }

  /**
   * 获取类型树数据
   */
  getTypeTreeData() {
    this.typeTreeLoading = true;
    AppApi.queryTypeTree()
      .then((res: any) => {
        if (res.code == 1) {
          // this.typeTreeData = res.data;
          this.typeTreeData = res.data;

          // 获取应用类型树下 type 为 1 的数据
          let getTypeTreeTypeForOne = function (dataList: any[]) {
            let res: any[] = [];
            dataList.forEach((item: any) => {
              if (item.type === "1") {
                res.push(item);
              }
              if (item.children && item.children.length > 0) {
                res.push(...getTypeTreeTypeForOne(item.children));
              }
            });
            return res;
          };

          let lastOne = getTypeTreeTypeForOne(res.data);
          if (lastOne.length > 0) {
            let lastOneElement = lastOne[0];
            this.defaultActiveMenu = lastOneElement.id;
            this.switchAppType(lastOneElement);
          }
        }
      })
      .finally(() => {
        this.typeTreeLoading = false;
      });
  }

  /**
   * 点击应用类型
   * @param item
   */
  switchAppType(item) {
    if (item.type && item.id) {
      if (item.type === "1") {
        this.defaultActiveMenu = item.id;
        let params = {
          applicationId: item.id,
          linkType: 2,
        };
        this.flowListLoading = true;
        AppApi.linkQueryLinkPublished(params)
          .then((res: any) => {
            if (res.code == 1) {
              this.flowListData = res.data;
            }
          })
          .finally(() => {
            this.flowListLoading = false;
          });
      }
    }
  }

  /**
   * 返回首页
   */
  goToHome() {
    this.$router.push({ path: "/front" });
  }

  /**
   * 跳转流程
   * @param item
   */
  jumpFlow(item) {
    // let token = TokenUtil.getToken();
    // let url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/create?processId=' + item.id + '&token=' + token;
    // window.location.href = url;

    let path = this.$router.resolve({
      path: "/front2/start",
      query: {
        id: item.id,
      },
    });
    window.open(path.href, "_blank");
  }
}
