var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      {
        staticStyle: {
          "border-left": "2px solid black",
          "border-bottom": "2px solid black",
        },
      },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { staticClass: "main-header", attrs: { span: 24 } },
              [
                _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        "font-weight": "bold",
                        "font-size": "20px",
                      },
                    },
                    [_vm._v("新建流程")]
                  ),
                ]),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                    attrs: { span: 12 },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "20px",
                          "font-weight": "bold",
                          cursor: "pointer",
                        },
                        on: { click: _vm.goToHome },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon",
                            attrs: {
                              t: "1643079214398",
                              viewBox: "0 0 1024 1024",
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "p-id": "2048",
                              width: "16",
                              height: "16",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M588.468659 257.265591H123.316451L371.227243 58.55359a31.947267 31.947267 0 1 0-39.614611-49.837737l-319.472671 255.578137v11.501016a30.669376 30.669376 0 0 0 0 4.472617v3.194727a30.669376 30.669376 0 0 0 0 4.472617v11.501016l319.472671 255.578137a31.947267 31.947267 0 1 0 40.253556-49.837737L123.316451 321.160125h465.152208C792.292223 321.160125 958.418011 464.283881 958.418011 640.632795s-166.125789 319.47267-369.949352 319.472671H95.841801a31.947267 31.947267 0 0 0 0 63.894534h492.626858C830.628943 1024 1022.312545 852.123703 1022.312545 640.632795s-191.683602-383.367205-433.843886-383.367204z",
                                fill: "#ffffff",
                                "p-id": "2049",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" 返回首页 "),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 10 } },
          [
            _c(
              "el-col",
              { attrs: { span: 5, offset: 0 } },
              [
                _c("el-card", { staticClass: "main-card" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              height: "30px",
                              "font-size": "var(--rootFontSize2)",
                              "font-weight": "900",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#fff" } }, [
                              _vm._v("流程分类"),
                            ]),
                          ]
                        ),
                        _c("div", {
                          staticStyle: {
                            width: "100%",
                            height: "2px",
                            "margin-top": "10px",
                            "margin-bottom": "10px",
                            "background-color": "#7f7f7f",
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { height: "100%" } },
                        [
                          _vm.typeTreeData.length === 0
                            ? _c("el-empty", {
                                staticStyle: { height: "calc(100% - 50px)" },
                                attrs: { description: "暂无流程分类" },
                              })
                            : _c(
                                "el-menu",
                                {
                                  staticStyle: { height: "calc(100% - 50px)" },
                                  attrs: {
                                    "default-active": _vm.defaultActiveMenu,
                                    "background-color": "#263955",
                                    "text-color": "#fff",
                                    "active-text-color": "#fff",
                                  },
                                },
                                [
                                  _c("cus-menu-item", {
                                    attrs: {
                                      "background-color": "#263955",
                                      "text-color": "#fff",
                                      "active-text-color": "#fff",
                                      "menu-list": _vm.typeTreeData,
                                    },
                                    on: { changeAppType: _vm.switchAppType },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 19, offset: 0 } },
              [
                _c("el-card", { staticClass: "main-card" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c("div", [
                        _c("div", { staticStyle: { width: "100%" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "30px",
                                "font-size": "var(--rootFontSize2)",
                                "font-weight": "900",
                              },
                            },
                            [_c("span", { staticStyle: { color: "#fff" } })]
                          ),
                          _c("div", {
                            staticStyle: {
                              width: "100%",
                              height: "2px",
                              "margin-top": "10px",
                              "margin-bottom": "10px",
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _vm.flowListData.length === 0
                            ? _c("el-empty", { attrs: { description: "" } })
                            : _c(
                                "el-row",
                                { attrs: { gutter: 50 } },
                                _vm._l(_vm.flowListData, function (item) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: item.id,
                                      staticClass: "usual-column",
                                      attrs: { span: 6 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "usual-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.jumpFlow(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.displayName) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }